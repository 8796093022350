.existingClient {
  //   margin-left: 200px;
  .first {
    display: flex;
    justify-content: space-between;
    max-width: 800px;
    gap: 50px;
    margin-top: 40px;
    & > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      & > label {
        color: #000;

        /* Button Small */
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
        margin-bottom: 4px;
      }
    }
    .spoc {
      & > label {
        color: #000;
        margin-bottom: 4px;

        /* Button Small */
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
      }
      & > input {
        border-radius: 4px;
        border: 1px solid var(--Black-20, rgba(17, 17, 19, 0.2));
        padding: 13px 16px;
        background: inherit;
        color: #4f4f4f;

        /* Body */
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
      }
    }
    .dropdown {
      //   height: 50px;
      max-width: 373px;
      font-size: 14px;
      font-family: "Inter";
      color: #000;
      border-radius: 4px;
      border: 1px solid var(--Black-20, rgba(17, 17, 19, 0.2));
    }
    .myControlClassName {
      height: 50px;
      display: flex;
      align-items: center;
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
    }
    .myArrowClassName {
      margin-top: 8px;
    }
  }

  .details {
    border-radius: 8px;
    background: #fff;
    max-width: 800px;
    padding: 20px 24px;
    margin-top: 40px;
    .titleC {
      color: #212121;
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
    }
    .subDetails {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      & > div {
        display: flex;
        flex-direction: column;
        & > div:nth-child(1) {
          color: #000;
          margin-bottom: 14px;
          /* Button Small */
          font-family: "Inter";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 19.6px */
        }
        & > div:nth-child(2) {
          color: #4f4f4f;
          font-family: "Inter";
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 21px */
        }
      }
    }
    .subDetails2 {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      & > div {
        & > div:nth-child(1) {
          color: #000;
          margin-bottom: 14px;
          /* Button Small */
          font-family: "Inter";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 19.6px */
        }
        & > div:nth-child(2) {
          color: #4f4f4f;
          font-family: "Inter";
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 21px */
        }
      }
    }
  }

  .next {
    border-radius: 6px;
    background: #4318ff;
    border: none;
    outline: none;
    padding: 12px 30px;
    color: #fff;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 40px;
    cursor: pointer;
  }
}
