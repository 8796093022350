.allApplications {
  margin-left: 200px;
  .title {
    max-width: 1000px;
    display: flex;
    margin-top: 50px;
    justify-content: space-between;
    & > div:nth-child(1) {
      color: #212121;
      font-family: "Inter";
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 30.8px */
    }
    & > div:nth-child(2) {
      width: fit-content;

      & > button {
        border-radius: 6px;
        background: #4318ff;
        color: #fff;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 9px 16px;
        border: none;
        outline: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
  .searchAndFilter {
    margin-top: 50px;
    display: flex;
    max-width: 1000px;
    // align-items: center;
    justify-content: space-between;
    .searchMain {
    }
    .filersButton {
      margin-bottom: 16px;
      border-radius: 6px;
      background: #4318ff;
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 7px 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
      outline: none;
      border: none;
      gap: 10px;
    }
    .mainDropdown {
      // order: 3;
      margin-right: -250px;
      & > button {
        border-radius: 6px;
        background: #4318ff;
        border: none;
        outline: none;
        color: #fff;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 8px 30px;
        cursor: pointer;
      }
    }
  }
  .jobTableWrapper {
    width: 100%;
    // max-width: 1400px;
    min-width: 1000px;
    overflow: auto;
    margin-top: 30px;

    .applicationTableContainer {
      border-collapse: collapse;
      width: 100%;
      background: #f3f0ff;
      .tableHead {
        background-color: #f3f0ff;

        .tableRow {
          border-bottom: 1px solid #ccc;
          padding: 20px;

          .headerContent {
            padding: 10px;
            text-align: left;
            font-weight: bold;
            padding: 20px;
            color: var(--secondary-black, #616161);
            font-family: "Inter";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 142.857% */
          }
        }
      }

      .tableBody {
        background-color: #fff;
        tr {
          td {
            padding: 10px;
            text-align: left;
            color: var(--secondary-black, #616161);
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            padding-left: 20px;

            button {
              background-color: #fff;
              color: #fff;
              padding: 5px;
              cursor: pointer;
              border-radius: 4px;
              border: 1px solid #4318ff;
              color: #4318ff;
              font-family: "Inter";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
            }
          }
          .jdAndEdit {
            display: flex;
            align-items: center;
            gap: 10px;
            color: #4318ff;
            svg,
            span {
              cursor: pointer;
            }
          }
        }
        tr:nth-child(even) {
          border: 1px solid #fafafa;
          background: #f6f6f6;
        }
      }
    }
  }
  .pageNo {
    margin-top: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
  }
}

.close {
  padding: 20px;
  position: relative;
  .closeX {
    position: absolute;
    top: 0;
    right: 0;
  }
  .title {
    font-family: "Inter";
    font-weight: 500;
    font-size: 16px;
    margin-top: 60px;
  }
  & > div {
    text-align: center;
    & > button {
      border-radius: 6px;
      background: #119675;
      border: none;
      outline: none;
      color: #fff;
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 8px 30px;
      cursor: pointer;
      margin: 0 auto;
      margin-top: 100px;
    }
  }
}
