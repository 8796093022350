.specificClient {
  margin-left: 200px;
  .backButton {
    width: fit-content;
    margin-top: 50px;
    & > button {
      display: flex;
      align-items: center;
      color: #212121;
      font-family: "Inter";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 28px */
      background: #fff;
      border: none;
      cursor: pointer;
      svg {
        font-size: 30px;
        color: #4318ff;
      }
    }
  }
  .title {
    color: #212121;
    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 30.8px */
    margin-top: 100px;
  }
  .searchAndFilter {
    margin-top: 34px;
  }
  .Specific-client-wrapper {
    width: 100%;
    max-width: 1300px;
    min-width: 1000px;
    overflow: auto;
    margin-top: 30px;

    .applicationTableContainer {
      border-collapse: collapse;
      width: 100%;
      background: #f3f0ff;
      .tableHead {
        background-color: #f3f0ff;

        .tableRow {
          border-bottom: 1px solid #ccc;
          padding: 20px;

          .headerContent {
            padding: 10px;
            text-align: left;
            font-weight: bold;
            padding: 20px;
            color: var(--secondary-black, #616161);
            font-family: "Inter";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 142.857% */
          }
        }
      }

      .tableBody {
        background-color: #fff;
        tr {
          td {
            padding: 10px;
            text-align: left;
            color: var(--secondary-black, #616161);
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            padding-left: 20px;

            button {
              background-color: #fff;
              color: #fff;
              padding: 5px;
              cursor: pointer;
              border-radius: 4px;
              border: 1px solid #4318ff;
              color: #4318ff;
              font-family: "Inter";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
            }
          }
        }
        tr:nth-child(even) {
          border: 1px solid #fafafa;
          background: #f6f6f6;
        }
      }
    }
  }
}
