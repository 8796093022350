.view-client {
  margin-left: 200px;
  .title {
    max-width: 1000px;
    display: flex;
    margin-top: 50px;
    justify-content: space-between;
    & > div:nth-child(1) {
      color: #212121;
      font-family: "Inter";
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 30.8px */
    }
    & > div:nth-child(2) {
      width: fit-content;

      & > button {
        border-radius: 6px;
        background: #4318ff;
        color: #fff;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 9px 16px;
        border: none;
        outline: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
  .searchAndFilter {
    margin-top: 50px;
    display: flex;
    max-width: 1000px;
    justify-content: space-between;
    .searchMain {
    }
    .filersButton {
      margin-bottom: 16px;
      border-radius: 6px;
      background: #4318ff;
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 7px 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
      outline: none;
      border: none;
      gap: 10px;
    }
  }
  .clientTableWrapper {
    width: 100%;
    max-width: 1300px;
    min-width: 1000px;
    overflow: auto;
    margin-top: 30px;

    .applicationTableContainer {
      border-collapse: collapse;
      width: 100%;
      background: #f3f0ff;
      .tableHead {
        background-color: #f3f0ff;

        .tableRow {
          border-bottom: 1px solid #ccc;
          padding: 20px;

          .headerContent {
            padding: 10px;
            text-align: left;
            font-weight: bold;
            padding: 20px;
            color: var(--secondary-black, #616161);
            font-family: "Inter";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 142.857% */
          }
        }
      }

      .tableBody {
        background-color: #fff;
        tr {
          td {
            padding: 10px;
            text-align: left;
            color: var(--secondary-black, #616161);
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            padding-left: 20px;

            button {
              background-color: #fff;
              color: #fff;
              padding: 5px;
              cursor: pointer;
              border-radius: 4px;
              border: 1px solid #4318ff;
              color: #4318ff;
              font-family: "Inter";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
            }
          }
        }
        tr:nth-child(even) {
          border: 1px solid #fafafa;
          background: #f6f6f6;
        }
      }
    }
  }
  .pageNo {
    margin-top: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}

.clientFilters {
  width: 100vw;
  height: 100vh;
  position: absolute;
  inset: 0;
  .popupMainWrapper {
    padding: 40px 30px;
    position: relative;

    width: 300px;
    float: right;
    margin-right: 50px;
    margin-top: 50px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 2px 2px 2px 0px rgba(79, 79, 79, 0.24),
      2px 2px 2px 0px rgba(79, 79, 79, 0.16);
    padding: 30px;
    .close {
      position: absolute;
      width: fit-content;
      top: 0;
      right: 0;
    }
    .title {
      color: #212121;
      font-family: "Inter";
      margin-top: 30px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 22.4px */
    }
    .date {
      color: #4f4f4f;
      font-family: "Inter";
      margin-top: 40px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
    }
    .fromTo {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      .item {
        & > div:nth-child(1) {
          margin-bottom: 7px;
        }
        input {
          border-radius: 4px;
          background: #f3f0ff;
          border: none;
          outline: none;
          padding: 8px;
          font-size: 14px;
          font-family: "Inter";
        }
      }
    }
    .dropdown {
      //   height: 50px;
      font-size: 14px;
      font-family: "Inter";
      color: #000;
      margin-top: 10px;
    }
    .myControlClassName {
      height: 50px;
      font-size: 14px;
      font-family: "Inter";
      color: #000;
    }
    .myMenuClassName {
      color: #000;
    }
    .buttons {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      margin-top: 32px;
      & > div {
        width: fit-content;
        cursor: pointer;
        padding: 12px;
      }
      & > div:nth-child(1) {
        color: #9c9ba4;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
      }
      & > div:nth-child(2) {
        color: #4318ff;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
      }
    }
  }
}
