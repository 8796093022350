.newJobReq {
  padding-left: 200px;
  border-radius: 8px 0px;
  background: #f8f9fb;
  min-height: 99vh;
  .backButton {
    width: fit-content;
    padding-top: 50px;
    & > button {
      display: flex;
      align-items: center;
      color: #212121;
      font-family: "Inter";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 28px */
      background: inherit;
      border: none;
      cursor: pointer;
      svg {
        font-size: 30px;
        color: #4318ff;
      }
    }
  }
  .title {
    color: #212121;
    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 30.8px */
    margin-top: 70px;
  }
  .clienttype {
    margin-top: 50px;
    max-width: 300px;
    .clienttype {
      color: #000;
      margin-bottom: 4px;
      /* Button Small */
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
    }
    .dropdown {
      //   height: 50px;
      font-size: 14px;
      font-family: "Inter";
      color: #000;
      margin-top: 10px;
      border-radius: 4px;
      border: 1px solid var(--Black-20, rgba(17, 17, 19, 0.2));
    }
    .myControlClassName {
      height: 50px;
      display: flex;
      align-items: center;
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
    }
    .myArrowClassName {
      margin-top: 8px;
    }
    .myMenuClassName {
      color: #000;
    }
  }
}
