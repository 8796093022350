.create-client {
  margin-left: 200px;
  border-radius: 8px 0px;
  //   background: #f8f9fb !important;
  .backButton {
    width: fit-content;
    margin-top: 50px;
    & > button {
      display: flex;
      align-items: center;
      color: #212121;
      font-family: "Inter";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 28px */
      background: #fff;
      border: none;
      cursor: pointer;
      svg {
        font-size: 30px;
        color: #4318ff;
      }
    }
  }
  .Allfields {
    margin-top: 50px;
    max-width: 800px;
    .subField1 {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 40px;
      gap: 50px;

      .subField2 {
        flex: 1;
        display: flex;
        flex-direction: column;
        & > label {
          color: #000;

          /* Button Small */
          font-family: "Inter";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 19.6px */
          margin-bottom: 4px;
        }
        .myControlClassName {
          height: 50px;
          border-radius: 4px;
          border: 1px solid var(--Black-20, rgba(17, 17, 19, 0.2));
          /* Body */
          font-family: "Inter";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          color: #000;
        }
        .myMenuClassName {
          font-size: 14px;
          font-family: "Inter";
        }
        & > input,
        & > textarea {
          resize: none;
          padding: 13px 16px;
          color: #4f4f4f;
          outline: none;
          border-radius: 4px;
          border: 1px solid var(--Black-20, rgba(17, 17, 19, 0.2));
          /* Body */
          font-family: "Inter";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
        }
        & > input::placeholder,
        & > textarea::placeholder {
          color: rgba(79, 79, 79, 0.3);

          /* Body */
          font-family: "Inter";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
        }
      }
    }
  }
  .update {
    border-radius: 6px;
    background: #4318ff;
    color: #fff;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 12px 30px;
    border: none;
    outline: none;
    cursor: pointer;
  }
}
