.login-page {
  position: relative;

  .login-wrapper {
    position: absolute;
    top: 18%;
    right: 18%;
    width: 500px;
    height: 410px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: -2px -2px 4px 0px rgba(33, 33, 33, 0.14), 2px 2px 4px 0px rgba(33, 33, 33, 0.14);
    padding: 35px;

    @media (max-width: 700px) {
      padding: 30px 20px;
      width: 80%;
    }

    .login {
      color: #212121;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .item1 {
      margin-top: 30px;

      input {
        height: 44px;
        padding: 13px 16px;
        margin-top: 8px;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: #4F4F4F;
        /* 22.4px */
        outline: none;
        border-radius: 8px;
        border: 1px solid rgba(17, 17, 19, 0.2);

        @media (max-width: 400px) {
          width: calc(100% - 50px);
        }
      }
    }

    .item2 {
      margin-top: 24px;

    }

    .input-wrapper {
      display: flex;
      flex-direction: column;

      label {
        color: #212121;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 22.4px */
      }

      .input-item2 {
        height: 44px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-radius: 8px;
        border: 1px solid rgba(17, 17, 19, 0.2);
        margin-top: 8px;

        input {
          width: 90%;
          outline: none !important;
          color: #A7A7A7;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          padding: 13px 16px;
          border: 1px solid #fff;
          border-radius: 8px;
        }

        span {
          padding-top: 13px;
          margin-right: 13px;
        }
      }
    }

    .remember {
      display: flex;
      justify-content: space-between;
      margin-top: 13px;

      .remember-text {
        display: flex;
        align-items: center;

        label {
          color: #4F4F4F;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          /* 19.6px */
          margin-left: 8px;
          cursor: default;
        }

        input[type="checkbox"] {
          /* ... */
          width: 20px;
          /* Increase the width as needed */
          height: 20px;
          /* Increase the height as needed */
          //   border: 1px solid;
          /* ... */
          cursor: pointer;
        }
      }

      .forgot {
        color: #4285f4;
        font-family: "Poppins";
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 156.5%;
        /* 25.04px */
        cursor: pointer;
      }
    }

    .login-button {
      button {
        width: 430px;
        height: 44px;
        border-radius: 8px;
        background: #062B6E;
        padding: 11px 0;
        margin-top: 32px;
        color: #fff;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        /* 22.4px */
        cursor: pointer;
      }
    }
  }
}

.login-main {
  display: flex;
  height: 100vh;

  .left-section {
    width: 65%;
    margin: 30px 0 0 60px;

    .logo {
      width: 50px;
      height: 50px;
    }

    .illustration{
      margin-top: 65px;
    }
  }

  .right-section {
    background-color: #D2E1FD;
    width: 35%;
  }
}