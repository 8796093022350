.create-job {
  margin-left: 200px;
  margin-bottom: 100px;
  .backButton {
    width: fit-content;
    margin-top: 50px;
    & > button {
      display: flex;
      align-items: center;
      color: #212121;
      font-family: "Inter";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 28px */
      background: #fff;
      border: none;
      cursor: pointer;
      svg {
        font-size: 30px;
        color: #4318ff;
      }
    }
  }
  .title {
    color: #212121;
    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 30.8px */
    margin-top: 70px;
  }
  .allfieldsJob {
    margin-top: 50px;
    .outer {
      display: flex;
      max-width: 1000px;
      justify-content: space-between;
      gap: 50px;
      .inner1,
      .innern {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-bottom: 40px;
        & > label {
          color: #000;

          /* Button Small */
          font-family: "Inter";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 19.6px */
          margin-bottom: 4px;
        }
        & > input {
          border-radius: 4px;
          border: 1px solid var(--Black-20, rgba(17, 17, 19, 0.2));
          padding: 13px 16px;
          color: var(--Black, #111113);
          outline: none;
          /* Body */
          font-family: "Inter";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
        }
        & > input::placeholder {
          color: #4f4f4f;

          /* Body */
          font-family: "Inter";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
        }
        .dropdown {
        }
        .myControlClassName {
          height: 50px;
          display: flex;
          align-items: center;
          font-family: "Inter";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
        }
        .myArrowClassName {
          margin-top: 8px;
        }
      }
      .innern {
        max-width: calc(50% - 25px);
      }
    }
  }

  .submit {
    border-radius: 6px;
    background: #4318ff;
    padding: 12px 30px;
    color: #fff;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    outline: none;
    border: none;
    margin-top: 40px;
  }
  .Jdmain {
    .title2 {
      color: #000;

      /* Button Small */
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      margin-bottom: 4px;
    }
    .dropBox {
      border-radius: 4px;
      border: 1px dashed var(--Black-20, rgba(17, 17, 19, 0.2));
      max-width: 800px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 36px 0;
      .label1 {
        color: #727272;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 25.2px */
      }
      .label2 {
        color: #4318ff;
        font-family: "Inter";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 21px */
        margin-top: 13px;
      }
      .selected {
        font-family: "Inter";
        font-size: 16px;
      }
    }
  }
}
